<script>
	import InputGroup from './inputGroup.svelte';

	export let hasSubGroup = false;
</script>

<div class="text-left pt-3 pb-6 px-8 border border-solid mb-5">
	<h2 class="text-2xl sm:text-2xl md:text-4xl my-5 border-b-2 border-solid pb-2">
		<slot name="title" />
	</h2>

	{#if hasSubGroup}
		<slot />
	{:else}
		<InputGroup>
			<slot />
		</InputGroup>
	{/if}
</div>

<style>
	h2,
	div {
		border-color: #ced4da;
	}
</style>
