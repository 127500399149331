<svelte:options accessors={true} />

<script>
	import Datepicker from 'svelte-calendar';

	import GroupLayout from '../Layout/GroupLayout.svelte';

	import Label from '../Layout/Label.svelte';

	export let fields = {
		deliveryDate: {
			label: 'Toivottu toimitusajankohta',
			value: new Date(),
		},
	};

	export const getValues = () => {
		const values = [];

			values.push({
			label: fields.deliveryDate.label,
			value: fields.deliveryDate.value.toString(),
		});

		return {
			name: 'Toimitus',
			inputs: values,
		};
	};

	const minDate = new Date();

</script>

<GroupLayout hasSubGroup={true}>
	<span slot="title">Toimitus</span>

	<div class='mb-4'>
		<Label>{fields.deliveryDate.label}</Label>
		<Datepicker start={minDate} bind:selected={fields.deliveryDate.value}  />
	</div>
</GroupLayout>
