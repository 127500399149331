<script>
	import Form from './Components/Form/Form.svelte';
	import ThankYou from './Components/ThankYou.svelte';
	import Info from './Components/Info.svelte';

	let formSubmitted = false;

	const onWindowLoad = () =>{
		const parent = window.parent;

		let scrollHeight = Math.max(
			document.body.scrollHeight, document.documentElement.scrollHeight,
			document.body.offsetHeight, document.documentElement.offsetHeight,
			document.body.clientHeight, document.documentElement.clientHeight
		);

		if(parent){
			parent.postMessage({
				smartFormHeight: scrollHeight
			}, "*");
		}
	}

</script>

<main class="px-6 pb-16 max-w-full">
	<div class="container max-w-full m-auto">
		{#if formSubmitted}
			<ThankYou />
		{:else}
			<Info />
			<Form
				on:submit={(e) => {
					formSubmitted = true;
				}}
			/>
		{/if}
	</div>
</main>

<svelte:window on:load={onWindowLoad} />
