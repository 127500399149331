import foundCompany from '../stores/foundCompany';
import { API_KEY, API_URL } from '../lib/constants';

export default function getCompany(id) {

	return new Promise((resolve, reject) => {
		fetch(`${API_URL}/details?id=${id}&&country=fi`, {
			headers: {
				'Content-type': 'application/json',
				'x-api-key': API_KEY,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				const nopAddress = data.postalAddress
					? data.postalAddress.find((address) => address.addressType === 'NOP')
					: null;
				const filteredAddress = nopAddress || data.postalAddress[0];
				const address = filteredAddress || {};

				const vatId = data.ids.find((type) => type.idType === 'VAT');
				const companyInfo = {
					orgname: data.name,
					businessId: id,
					...address,
					vatId: vatId && vatId.idValue ? vatId.idValue : null,
				};

				const invoice = data.receivingFinvoiceAddress
					? data.receivingFinvoiceAddress[0]
					: {};

				const invoiceInfo = {
					...address,
					...invoice,
					primaryEmail: data.primaryEMail ?? null,
				};

				const response = { companyInfo, invoiceInfo };

				foundCompany.set({
					...response,
					data: {
						languageCode: data.languageCode,
						countryCode: data.countryCode,
						homePage: data.homePage,
						primaryEMail: data.primaryEMail,
						phoneNumber: data.phoneNumber,
					},
				});

				return resolve(response);
			})
			.catch((e) => {
				foundCompany.set(null);
				return reject();
			});
	});
}
