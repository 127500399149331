<svelte:options accessors={true} />

<script>
	import GroupLayout from '../Layout/GroupLayout.svelte';

	import InputCheckbox from '../Controls/InputCheckbox.svelte';

	export let fields = {
		cards: {
			label: 'Haluan lisätä lahjalaatikkojen tarjoukseen myös kortit',
			required: false,
			checked: false,
			value: '0',
		},
	};

	export const getValues = () => {
		const values = [];
		for (const key of Object.keys(fields)) {
			values.push({
				label: fields[key].label,
				value: fields[key].checked,
			});
		}
		return {
			name: 'Haluan lisätä lahjalaatikkojen tarjoukseen myös kortit',
			inputs: values,
		};
	};

</script>

<GroupLayout hasSubGroup={true}>
	<span slot="title">Lisää lahjalaatikkoon personoitu tervehdyksesi</span>

	<p class="my-2">
		Voimme lisätä lahjalaatikkoon tuotteiden mukaan myös oman tervehdyksesi korttina.
	</p>

	<InputCheckbox {...fields.cards} bind:checked={fields.cards.checked} />

	<p class="mt-4 italic color">
		Painovalmis aineisto on helppo muodostaa sivuillamme olevassa
		<b>
			<a target="_blank" href="https://lahjalaatikossa.fi/luo-oma-kortti/">sovelluksessa</a>
		</b>. Voit valita valmiista korttikuvistamme tai ladata oman kuvan. Oma teksti on vaivaton
		muotoilla sopivaksi. Korkealaatuisen postikortin koko on 15x15cm. Aineisto tulee lähettää
		meille sovelluksen kautta viimeistään 2 viikkoa ennen lahjalaatikoiden toimituspäivää.
		Kortit maksavat 0,30-2,50€ (alv 0) riippuen määrästä.
	</p>
</GroupLayout>

<style>
	.color {
		color: #6c757d;
	}
</style>
