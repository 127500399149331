export const getOrigin = async () => {
	const requestUrl = 'https://www.cloudflare.com/cdn-cgi/trace';

	const res = await fetch(requestUrl);
	let origin = {};
	try {
		origin = await res.text();
		origin = textToJson(origin);
	} catch (error) {}

	// console.log(origin);

	origin = {
		origin: {
			origin: window.location.origin,
			ipAddress: origin.ip,
		},
		language: navigator.language,
		country: origin.loc,
	};

	return origin;
};

function textToJson(response) {
	let res = response.split(/\r?\n/g).filter((line) => line.trim());
	let curr = null;
	const result = res.reduce((acc, line) => {
		let [key, ...value] = line.split('=');
		value = value.join(':').trim();
		if (key.startsWith(' ')) {
			acc[curr.key][curr.index][key.trim()] = value;
		} else {
			acc[key.trim()] = value;
		}
		return acc;
	}, {});

	return result;
}
