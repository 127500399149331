<script>
	import InputTitle from '../Layout/InputTitle.svelte';

	const agreements = [
		{
			text: 'Hyväksyn Lahjalaatikossa-palvelun toimitusehdot.',
			checked: false,
			required: true,
			link: 'https://lahjalaatikossa.fi/toimitusehdot/'
		},
		{
			text: 'Hyväksyn tietojeni käytön ja tallentamisen.',
			checked: false,
			required: true,
			link: 'https://lahjalaatikossa.fi/tietosuojaseloste/'
		},
	];

	const returnNum = (index) => {
		return agreements[index].checked ? 1 : 0;
	};

	export const getValues = () => {
		return {
			terms: [
				{
					termName:  'Hyväksyn Lahjalaatikossa-palvelun toimitusehdot',
					status: returnNum(0),
				},
				{
					termName: 'Hyväksyn Kauppahalli24 tilaus ja toimitusehdot',
					status: returnNum(1),
				},
			],
		};
	};
</script>

<div class="my-6">
	{#each agreements as agreement, index}
		<div class="flex">
			<input
				type="checkbox"
				id="agreement{index}"
				required={agreement.required}
				bind:checked={agreement.checked}
			/>

			<InputTitle inline={true}>
				<label for="agreement{index}" class="inline">
					<a href={agreement.link} target='_blank'>{agreement.text}</a>
				</label>
			</InputTitle>
		</div>
	{/each}
</div>

<style>
	input {
		margin: auto 0;
		width: 24px;
		min-width: 24px;
	}

	a{
		text-decoration: underline;
	}
</style>
