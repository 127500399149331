export const getAddressFields = (required = true, disabled = false, visible = true) => {
	return {
		street: {
			label: 'Katuosoite',
			required,
			disabled,
			visible,
			value: '',
		},
		city: {
			label: 'Kaupunki',
			required,
			disabled,
			visible,
			value: '',
		},
		postalCode: {
			label: 'Postinumero',
			required,
			disabled,
			visible,
			value: '',
		},
		country: {
			label: 'Maa',
			required,
			disabled,
			visible,
			value: '',
		},
	};
};
