<script>
	export let getSelectionLabel = undefined;
	export let item = undefined;
</script>

<style>
	.selection {
		text-overflow: ellipsis;
		overflow-x: hidden;
		white-space: nowrap;
		color: var(--inputColor, #3f4f5f);
	}
</style>

<div class="selection">
	{@html getSelectionLabel(item)}
</div>
