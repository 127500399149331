<svelte:options accessors={true} />

<script>
	import GroupLayout from '../Layout/GroupLayout.svelte';

	import getCompanies from '../../../Api/search';
	import getCompany from '../../../Api/getCompany';
	import { getAddressFields } from '../helpers/addressFields';

	import foundCompany from '../../../stores/foundCompany';
	import SelectLabel from '../Controls/SelectLabel.svelte';
	import Input from '../Controls/Input.svelte';

	const loadOptionsInterval = 500;
	const searchInputMin = 3;
	let selectedCompany;

	const onSelect = (e) => {
		if (e.detail !== selectedCompany) {
			selectedCompany = e.detail;
		}
	};

	export let fields = {
		name: {
			label: 'Yritys',
			required: true,
			value: '',
			selectedValue: undefined,
			placeholder: '',
			loadOptions: getCompanies,
			optionIdentifier: 'name',
			getOptionLabel: (option) => option.name,
			getSelectionLabel: (option) => option.name,
			loadOptionsInterval,
			searchInputMin,
			onSelect,
		},
		businessId: {
			label: 'Y-tunnus',
			required: true,
			value: '',
			selectedValue: undefined,
			placeholder: '',
			isDisabled: true,
			loadOptions: getCompanies,
			optionIdentifier: 'businessId',
			getOptionLabel: (option) => option.businessId,
			getSelectionLabel: (option) => option.businessId,
			loadOptionsInterval,
			searchInputMin,
			onSelect,
		},
		vatId: {
			label: 'ALV-tunnus',
			required: true,
			disabled: true,
			value: '',
		},
		...getAddressFields(true, true),
	};

	export const getValues = () => {
		return {
			company: {
				name: fields.name.value,
				ids: [
					{
						idType: 'BID',
						idValue: fields.businessId.value,
					},
					{
						idType: 'VAT',
						idValue: fields.vatId.value,
					},
				],
				addresses: [
					{
						// id: 1,
						// addressType: $foundCompany.companyInfo.addressType,
						// country: fields.country.value,
						postalCode: fields.postalCode.value,
						city: fields.city.value,
						street: fields.street.value,
					},
				],
				// ...$foundCompany.data,
			},
		};
	};

	$: if (selectedCompany && selectedCompany.businessId) {
		updateSelectedFields();
	}

	$: if (selectedCompany && selectedCompany.businessId) {
		getCompany(selectedCompany.businessId);
	}

	$: if ($foundCompany && $foundCompany.companyInfo) {
		setCompanyFields($foundCompany.companyInfo);
	} else {
		setCompanyFields({});
	}

	const updateSelectedFields = () => {
		fields.businessId.selectedValue = selectedCompany;
		fields.name.selectedValue = selectedCompany;
	};

	const setCompanyFields = (data) => {
		for (const [key, value] of Object.entries(data)) {
			if (fields[key]) {
				fields[key].value = value;
			}
		}
	};
</script>

<GroupLayout>
	<span slot="title">Yrityksen tiedot</span>

	<SelectLabel field={fields.name} bind:value={fields.name.value} />
	<SelectLabel field={fields.businessId} bind:value={fields.businessId.value} />

	<Input {...fields.vatId} bind:value={fields.vatId.value} />
	<Input {...fields.street} bind:value={fields.street.value} />
	<Input {...fields.city} bind:value={fields.city.value} />
	<Input {...fields.postalCode} bind:value={fields.postalCode.value} />
	<Input {...fields.country} bind:value={fields.country.value} />
</GroupLayout>
