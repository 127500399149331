<svelte:options accessors={true} />

<script>
	import GroupLayout from '../Layout/GroupLayout.svelte';
	import Input from '../Controls/Input.svelte';
	import foundCompany from '../../../stores/foundCompany';

	export let fields = {
		firstName: {
			label: 'Etunimi',
			value: '',
		},
		lastName: {
			label: 'Sukunimi',
			value: '',
		},
		phone: {
			label: 'Puhelinnumero',
			required: true,
			value: '',
		},
		email: {
			label: 'Sähköposti',
			type: 'email',
			required: true,
			value: '',
		},
	};

	export const getValues = () => {
		return {
			persons: [{
					firstName: fields.firstName.value,
					lastName: fields.lastName.value,
					phoneNumber: fields.phone.value,
					email: fields.email.value
				}]
		};
	};
</script>

<GroupLayout>
	<span slot="title">Yhteyshenkilö</span>

	<Input {...fields.firstName} bind:value={fields.firstName.value} />
	<Input {...fields.lastName} bind:value={fields.lastName.value} />
	<Input {...fields.phone} bind:value={fields.phone.value} />
	<Input {...fields.email} bind:value={fields.email.value} />
</GroupLayout>
