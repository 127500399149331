<script>
	import { getOrigin } from './helpers/getOrigin';
	import { validate } from './helpers/validate';

	import { createEventDispatcher, onMount } from 'svelte';

	import Company from './Groups/Company.svelte';
	import Buyer from './Groups/Buyer.svelte';
	import Agreements from './Groups/Agreements.svelte';
	import Delivery from './Groups/Delivery.svelte';
	import Custom from './Groups/Custom.svelte';
	import CustomGiftBox from './Groups/CustomGiftBox.svelte';
	import { API_KEY, API_URL } from '../../lib/constants';

	const dispatch = createEventDispatcher();

	const groups = {
		company: null,
		buyer: null,
		delivery: null,
		// packages: null,
		custom: null,
		customGiftBox: null,
	};

	let agreementComponent;

	let origin;
	let submitting = false;
	let error = null;

	onMount(async () => {
		origin = await getOrigin();
	});

	const validateGroup = (component) => {
		const { valid, fieldsObj } = validate(component.fields);
		component.fields = fieldsObj;
		return valid;
	};

	const submit = async (e) => {
		let valid = true;
		error = null;

		Object.values(groups).forEach((group) => {
			const groupValid = validateGroup(group);
			valid = !groupValid ? groupValid : valid;
		});

		if (!valid) {
			return;
		}

		const data = {
			requestInfo: {
				riskData: {
					fingerprint: 'ABCD',
					...origin,
				},
			},
		};

		const components = [];

		for (const [key, group] of Object.entries(groups)) {
			if (key === 'delivery' || key === 'packages' || key === 'customGiftBox') {
				components.push(group.getValues());
			} else {
				Object.assign(data, group.getValues());
			}
		}

		Object.assign(data, {
			components: components,
		});
		Object.assign(data, agreementComponent.getValues());

		submitting = true;

		const errorMsg = 'An error occurred';

		try {
			const res = await fetch(`${API_URL}/v2/smartform`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'x-api-key': API_KEY,
				},
				body: JSON.stringify(data),
			});

			const json = await res.json();

			if (res.status === 200 && !json.errorCode) {
				dispatch('submit', res);
			} else {
				error = errorMsg;
			}
		} catch (e) {
			console.log(e);
			error = errorMsg;
		}

		console.log('error', error);
		submitting = false;
	};
</script>

<form on:submit|preventDefault={submit}>
	<Company bind:this={groups.company} />

<!--	<Packages bind:this={groups.packages} />-->
	<Custom bind:this={groups.custom} />
	<CustomGiftBox bind:this={groups.customGiftBox} />

	<Buyer bind:this={groups.buyer} />
	<Delivery bind:this={groups.delivery} />

	<Agreements bind:this={agreementComponent} />

	<div class="flex mt-6">
		<button type="submit" class="flex" disabled={submitting}>
			Lähetä
			<span class="loader {submitting ? 'inline-block' : 'hidden'}">
				<img src="/loader.svg" alt="" />
			</span>
		</button>

		{#if error}
			<span class="block my-auto mx-6 text-red-500">
				{error}
			</span>
		{/if}
	</div>
</form>

<style>
	.loader {
		width: 15px;
		margin: auto 0 auto 10px;
	}

	button {
		padding: 0.5rem 1rem;
		font-size: 1.25rem;
	}
</style>
