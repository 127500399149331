const setFieldStatus = (field, status, error) => {
	field.status = status;
	field.error = error;
};

const emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validateRe = (re, string) => {
	return re.test(String(string).toLowerCase());
};

const isValueEmpty = (field) => {
	let value = field.value;
	if (value && typeof value === 'string') {
		value = value.trim();
	}

	switch (field.type) {
		case 'radio':
			return value === '' || value === null;
		case 'checkbox':
			return field.checked === false;
		default:
			return typeof value === 'undefined' || !value || (value && value.length === 0);
	}
};

const validateType = (field) => {
	const valid = true;
	if (field.type === 'email' || field.regex) {
		const isValueValid = validateRe(
			field.type === 'email' ? emailRe : field.regex,
			field.value,
		);

		if (!isValueValid) {
			valid = false;
			setFieldStatus(field, 'invalid', 'Field is invalid');
		}
	}

	return valid;
};

const validateMax = (field) => {
	const valid = true;
	if (field.max && field.value.length > field.max) {
		valid = false;
		setFieldStatus(field, 'invalid', `Field is too long (${field.max} max)`);
	}

	return valid;
};

export const validate = (fieldsObj) => {
	let valid = true;

	for (const [key, field] of Object.entries(fieldsObj)) {
		if (Array.isArray(field)) {
			for (const subField of field) {
				const subResult = validate(subField);
				if (valid) {
					valid = subResult.valid;
				}
			}
		} else {
			setFieldStatus(field, 'valid', '');

			const isEmpty = isValueEmpty(field);

			if (field.required && isEmpty) {
				setFieldStatus(field, 'required', 'Field is required');
				valid = false;
			} else if (!isEmpty) {
				const isTypeValid = validateType(field);
				if (!isTypeValid) {
					valid = false;
				}

				const isValidMax = validateMax(field);
				if (!isValidMax) {
					valid = false;
				}
			}
		}
	}

	return { valid, fieldsObj };
};
