<svelte:options accessors={true} />

<script>
	import GroupLayout from '../Layout/GroupLayout.svelte';

	import Textarea from '../Controls/Textarea.svelte';

	export let fields = {
		custom: {
			label: 'Oletteko miettineet esimerkiksi budjettia, tuotetoiveita, määriä tai aikataulua? Kirjoita alla olevaan kenttään tarpeistanne tarkemmin.',
			value: '',
		},
		additional: {
			label: 'Onhan kaikki huomioitu? Kirjoita mahdolliset lisätoiveet ja -tarpeet alla olevaan kenttään.',
			value: '',
		},
	};

	export const getValues = () => {
		return {
			textBlocks: [
				{
					name: fields.custom.label,
					text: fields.custom.value,
				},
				{
					name: fields.additional.label,
					text: fields.additional.value,
				},
			],
		};
	};
</script>

<GroupLayout hasSubGroup={true}>
	<span slot="title">Räätälöitävä paketti</span>

	<Textarea {...fields.custom} bind:value={fields.custom.value} />
	<Textarea {...fields.additional} bind:value={fields.additional.value} />
</GroupLayout>
