<script>
	import Label from '../Layout/Label.svelte';

	export let value = '';
	export let label = '';
	export let error = '';
	export let required = false;
	export let visible = true;
	export let disabled = false;
	export let max = null;
</script>

<Label {label} {error} {required} {visible}>
	<textarea class="w-full" {disabled} {max} bind:value on:input rows="5" />
</Label>
