<script>
	import InputTitle from '../Layout/InputTitle.svelte';

	export let label = '';
	export let error = '';
	export let required = false;
	export let visible = true;
	export let inline = false;
	export let inRow = false;
</script>

<!-- svelte-ignore a11y-label-has-associated-control -->
<label
	class={visible && inRow ? 'sm:block md:flex' : inline ? 'flex items-center' : 'block'}
	class:hidden={!visible}
>
	{#if inline}
		<slot />
	{/if}

	<InputTitle {required} {inline}>{label}</InputTitle>

	{#if !inline}
		<slot />
	{/if}

	{#if error}<span class="text-xs text-red-500">{error}</span>{/if}
</label>
