<script>
	import Label from '../Layout/Label.svelte';

	export let checked = false;
	export let label = '';
	export let required = false;
	export let error = '';
</script>

<Label {label} {error} {required} inline={true}>
	<input type="checkbox" class="my-auto" on:change bind:checked />
</Label>

<style>
	input {
		min-width: 24px;
	}
</style>
