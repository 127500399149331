<script>
	import Label from '../Layout/Label.svelte';
	import Select from './Select/Select.svelte';

	export let field;
	export let value = '';

	const onSelect = (e, field) => {
		value = e.detail[field.optionIdentifier ?? 'value'];

		if (field.onSelect) {
			field.onSelect(e);
		}
	};
</script>

<Label label={field.label} error={field.error} required={field.required}>
	{#if field.loadOptions}
		<Select
			loadOptions={field.loadOptions}
			optionIdentifier={field.optionIdentifier}
			getSelectionLabel={field.getSelectionLabel}
			getOptionLabel={field.getOptionLabel}
			loadOptionsInterval={field.loadOptionsInterval}
			searchInputMin={field.searchInputMin}
			placeholder={field.placeholder}
			isDisabled={field.isDisabled}
			bind:selectedValue={field.selectedValue}
			on:select={(e) => onSelect(e, field)} />
	{:else}
		<Select
			items={field.items}
			placeholder={field.placeholder}
			bind:selectedValue={field.selectedValue}
			on:select={(e) => onSelect(e, field)} />
	{/if}
</Label>
