import { API_URL, API_KEY } from '../lib/constants';

export default function getCompanies(text = null, businessId = null) {
	const query = text ? encodeURIComponent(text) : businessId;

	return new Promise((resolve, reject) => {
		fetch(`${API_URL}/search?name=${query}&country=fi`, {
			headers: {
				'Content-type': 'application/json',
				'x-api-key': API_KEY,
			},
		})
			.then((response) => response.json())
			.then((json) => {
				const data = json.map((entry) => {
					entry.label = `${entry.name} (${entry.businessId})`;
					return entry;
				});

				resolve(data);
			})
			.catch((e) => reject());
	});
}
