<script>
	import { onMount } from 'svelte';
	import Label from '../Layout/Label.svelte';

	export let type = 'text';
	export let value = '';
	export let label = '';
	export let error = '';
	export let required = false;
	export let visible = true;
	export let disabled = false;
	export let max = null;
	export let inRow = false
	export let afterInput = null

	let inputElement;
	onMount(() => {
		inputElement.type = type;
	});

	const onInput = (event) => {
		if (type === 'number') {
			const newValue = event.target.value.replace(/\D/g, '');
			event.target.value = newValue;
		}
	};
</script>

<Label {label} {error} {required} {visible} {inRow}>
	<input class={!inRow ? 'w-full' : 'w-24'} {disabled} {max} bind:this={inputElement} bind:value on:input={onInput} />

	{#if afterInput}
		<span class='inline-block my-auto ml-2'>{afterInput}</span>
	{/if}
</Label>
